import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

const GuideLondon1 = () => (
  <Layout>
    <SEO title="London Sauna Guide - Near Shaftesbury Avenue" />
    <h1>Near Shaftesbury Avenue, London</h1>
          <p>These hotels are handy if you’re going to the Harry Potter play, or other west end shows.</p>
          <p>Unfortunately, filtering by ‘has sauna’ is unreliable if you want that to mean ‘has a sauna actually within
              the hotel that I can use at a sensible time at no extra charge’.</p>
          <p>We’ve pored through reviews and photos to figure out what’s what, but this can change so do check before
              you make a booking on the basis of this info.</p>
          <p><strong>Courthouse Hotel</strong><br />
              has a small pool and a sauna (saunarium), no other wet facilities (October 2016). Spa treatments
              available; ‘Sanook Spa’ is manned from 10 am till 7pm. There are separate male and female showers in small
              changing facility. Saunarium&nbsp;appears to have glass door and two chairs facing it; hooks to left.
              Towel rack near pool.</p>
          <p><strong>W London Leicester Square</strong><br />
              next to M&amp;Ms world</p>
          <p>We hear this has suites, a ‘Screening suite’ has large private steam room and a projector to watch films in
              bed. There might be one with a private sauna.</p>
          <p>There is however for all guests a sauna in back corner of gym. Plus steam room. No pool or
              jacuzzi.&nbsp;There are 2 changing rooms that include showers – change here in private then put your stuff
              in the mixed lockers in the general area. Gym is 24 hours, sauna seems to follow spa times,&nbsp;daily
              from 10:00 AM to 9:00 PM.</p>
          <p><strong>The Piccadilly London West End</strong><br />
              Spa in basement has fitness room, sauna, steam room and facilities for treatments.&nbsp;This is an oddly
              laid out spa. Reception opens out in open plan fashion, there is jacuzzi, open shower, hammock seating,
              treatment rooms, fitness room, manicure station, product counter, changing rooms, all off the one L shape
              room. That said, the sauna looks gorgeous. Automatic. U shaped bottom bench (tho knees may be close to
              someone sat opposite) and higher bench to back.</p>
          <p><strong>St. James’ Court, A Taj Hotel</strong><br />
              Small saunas and scented steam rooms, seemingly a pair for each gender.</p>
          <p><strong>One Aldwych</strong><br />
              Mixed sauna, steam room, and heated saltwater pool. The locker room has&nbsp;showers, body wash, shampoo,
              hairdryers, etc.&nbsp;From photos, this is a large sauna. 3 levels to rear, 2 levels to side. Automatic.
              Two open showers outside. There is a bar to hang towels on, and two hooks for robes.</p>
          <p><strong>The Savoy</strong><br />
              Small saunas and steam rooms in the male and female changing rooms. Open &amp; heated up early.</p>
          <p><strong>Sofitel London St James</strong><br />
              Sauna and steam free for private use by request if you’re having a 60 minute + treatment at So-SPA, if
              available. So book in advance.</p>
          <p>GYM LINK</p>
          <p><strong>The Waldorf Hilton London<br />
          </strong>You have access to an attached DW Fitness with pool, sauna and steam room – which provides a gym
              towel and not bath towels so take one from your room. Reviews seem variable.</p>
          <p>&nbsp;</p>
          <p><strong>St Giles London – A St Giles Hotel</strong><br />
              Has a YMCA attached, which last time I visited has a pool-side sauna on a different floor to the changing
              room. Use of facility is extra.</p>
          <p><strong>Strand Palace</strong><br />
              Link with nearby Virgin club. However many reviews mention that rooms get hot like a sauna.</p>
          <p><strong>Thistle Holborn</strong><br />
              5 quid discount on Nuffield Covent Garden Centre</p>
          <p><strong>The Fielding</strong><br />
              free pass to Covent Garden Gym, provides towels for sauna (nov 2011)</p>
          <p><strong>The Montague on The Gardens</strong><br />
              Some fitness facilities within the hotel as well as complimentary access to our preferred health club,
              which also provides a sauna.</p>
          <p>NO SAUNA but worth mentioning:</p>
          <p><strong>Kingsway Hall Hotel<br />
          </strong>No sauna. But there is a steam room and hot tub.</p>
          <p><strong>Radisson Blu Edwardian Kenilworth Hotel</strong><br />
              No sauna. Some suites have a ‘steam sauna’. Fitness room includes a steam room, fits 4, no real changing
              facilities.</p>
          <p><strong>Le Meridien Piccadilly</strong><br />
              Some earlier reviews say there’s a sauna but I believe this now has just a hammam / steam room and pool
              (one of the largest indoor pools in central London).</p>
          <p><strong>Sanderson London Hotel</strong><br />
              Sometimes mistakenly called a sauna, the spa has a steam room which is apparently very good.</p>
          <p><strong>Westbury Mayfair</strong><br />
              No sauna. Steam room seemingly always broken.</p>
          <p>&nbsp;</p>


    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default GuideLondon1
